<template>
  <v-app>
    <app-nav />
    <v-main>
      <v-container :fill-height="!isAuthenticated">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AppNav from "@/components/AppNav";
import { mapState } from "vuex";

export default {
  name: "App",

  components: { AppNav },

  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated
    })
  }
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
</style>
