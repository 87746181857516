import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import timeTracker from "./timeTracker";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { auth, timeTracker }
});
