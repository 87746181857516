import axios from "axios";
import VueCookies from "vue-cookies";

class Api {
  constructor() {
    let baseURL = process.env.VUE_APP_BASE_API_PATH;
    this.axios = axios.create({ baseURL });
  }

  authorize() {
    if (!this.hasJwt()) {
      return this.redirectToLogin();
    }

    this.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.getJwt()}`;

    return this;
  }

  hasJwt() {
    return !!this.getJwt();
  }

  getJwt() {
    return this.getCookie("user_token");
  }

  getCookie(name) {
    return VueCookies.get(name);
  }

  setCookie(name, value) {
    VueCookies.set(name, value);
  }

  deleteCookie(name) {
    VueCookies.remove(name);
  }

  redirectToLogin() {
    return (window.location.href = "/login");
  }

  get(path, params = {}) {
    return this.request({
      method: "GET",
      url: path,
      responseType: "json",
      params
    });
  }

  put(path, payload) {
    return this.request({
      method: "PUT",
      url: path,
      responseType: "json",
      data: payload
    });
  }

  post(path, payload) {
    return this.request({
      method: "POST",
      url: path,
      responseType: "json",
      data: payload
    });
  }

  async request(options) {
    const { data } = await this.axios.request(options).catch(error => {
      if ([401, 403].includes(error.response.status)) {
        return this.redirectToLogin();
      }

      return Promise.reject(error);
    });

    return data;
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

export default new Api();
