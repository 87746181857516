<template>
  <div>
    <v-app-bar v-if="isAuthenticated" app>
      <v-app-bar-nav-icon @click="navOpen = true"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-spacer />
      <v-menu :offset-y="true" :close-on-click="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs" depressed color="primary" text>
            {{ user.first_name }} {{ user.last_name }}
            <v-icon color="primary">mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item to="/logout">
            <v-list-item-title
              ><v-icon>mdi-logout-variant</v-icon> Log Out</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="navOpen" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(link, index) in filteredLinks"
            :key="index"
            :selected="true"
            :to="link.url"
          >
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn block to="/logout" color="error">
            Log Out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppNav",

  data() {
    return {
      navOpen: false,
      links: [
        {
          title: "Dashboard",
          url: "/",
          icon: "mdi-view-dashboard",
          requiresAuth: true,
          requiresAdmin: false
        },
        {
          title: "Time Tracker",
          url: "/time-tracker",
          icon: "mdi-calendar-clock",
          requiresAuth: true,
          requiresAdmin: false
        }
      ]
    };
  },

  computed: {
    ...mapState({
      user: state => state.auth.currentUser,
      isAuthenticated: state => state.auth.isAuthenticated
    }),

    pageTitle() {
      return this.$route.name;
    },

    filteredLinks() {
      if (!this.user.is_admin) {
        return this.links.filter(link => link.requiresAdmin != true);
      }

      return this.links;
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  color: $primary;
}
</style>
