import Api from "@/lib/Api";

export default {
  state: {
    timeFilterOptions: [],
    laborCharges: {}
  },

  mutations: {
    setLaborCharges(state, laborCharges) {
      state.laborCharges = laborCharges;
    },

    timeFilterOptions(state, timeFilterOptions) {
      state.timeFilterOptions = timeFilterOptions;
    }
  },

  actions: {
    getLaborCharges({ commit }) {
      return Api.authorize()
        .get("/time-tracker/for-current-user")
        .then(data => {
          commit("setLaborCharges", data.data);
        });
    }
  }
};
