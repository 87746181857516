import Api from "@/lib/Api";
import VueJwtDecode from "vue-jwt-decode";

export default {
  state: {
    isAuthenticated: false,
    currentUser: {}
  },

  mutations: {
    setCurrentUser(state, token) {
      Api.setCookie("user_token", token);
      let user = VueJwtDecode.decode(token);
      state.currentUser = user.data;
      state.isAuthenticated = true;
    }
  },

  actions: {
    login({ commit }, { email, password }) {
      return Api.post("/identity", {
        email: email,
        password: password
      }).then(data => {
        commit("setCurrentUser", data.token);
      });
    },

    initUser({ commit }, token) {
      commit("setCurrentUser", token);
    }
  }
};
